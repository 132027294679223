import * as React from "react"
import iconInstagram from '../../images/icon-instagram.svg'
import iconOpensea from '../../images/icon-opensea.svg'
import iconTiktok from '../../images/icon-tiktok.svg'
import './styles.scss';

const iconStyles = {
  width: 25,
  height: 25,
  margin: '0 0 0 1rem'
}
const iconStyles2 = {
  width: 23,
  height: 23,
  margin: '0 0 0 1rem'
}

const TopNav = () => {
  return (
    <nav>
      <h1><a href="/">Mister Jey</a></h1>
      <div>
        <a href="https://opensea.io/MisterJey" target="_blank"><img style={iconStyles2} src={iconOpensea} /></a>
        <a href="https://www.instagram.com/misterjeyofficial/" target="_blank"><img style={iconStyles} src={iconInstagram} /></a>
        <a href="https://www.tiktok.com/@misterjeyofficial" target="_blank"><img style={iconStyles} src={iconTiktok} /></a>
      </div>
    </nav>
  )
}

export default TopNav
