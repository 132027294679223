import React, { useState } from "react"
import Layout from '../../components/Layout';
import TopNav from '../../components/TopNav';
import './styles.scss';

const innitialMeals = [
  {
    title: 'Veal with & rice',
    ingredients: ['Veal', 'Rice', 'Catotte', 'shallot', 'Maple syrup']
  },
  {
    title: 'Breaded fish & Pasta',
    ingredients: ['Fish', 'Breadcrumbs', 'gluten free pasta', 'vegan cheese']
  },
  {
    title: 'Turkey & mashed sweet potato',
    ingredients: ['Turney', 'Sweet potato', 'brocolie', 'shallot']
  },
  {
    title: 'Homemade pizza',
    ingredients: ['Gluten free pizza crust', 'Olive', 'vegan cheese', 'italien tomato', 'basilic or pesto', 'mushroom', 'spinash', 'courgette']
  },
  {
    title: 'Vegetable Soup',
    ingredients: ['Sweet potato']
  },
]

function shuffleArray(array) {
  let newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

const MealPlanPage = () => {
  const [meals, setMeals] = useState(innitialMeals);
  const onClickGenerate = () => {
    setMeals(shuffleArray(meals));
  }

  const getGroceryItems = () => {
    let result = [];
    meals.forEach((x, index) => {
      result = [
        ...result,
        ...x.ingredients,
      ]
    });
    return result.filter(function(item, pos) {
      return result.indexOf(item.toLowerCase()) == pos;
    });
  }

  return (
    <Layout className="meal-plan">
      <TopNav />
      <h1>Meal Plan</h1>
      <button onClick={onClickGenerate}>Generate</button>
      <ul>
        {meals.map((x, index) => {
          return (
            <li key={index}>
              <div>Day {index+1}: {x.title}</div>
              <small>{x.ingredients.join(', ')}</small>
            </li>
          )
        })}
      </ul>
      <h2>Grocery list</h2>
      <ul>
        {getGroceryItems().map((x, index) => {
          return (
            <li key={index}>
              <div>{x}</div>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

export default MealPlanPage
