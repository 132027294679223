import * as React from "react"
import TopNav from '../TopNav'
import './styles.scss';

const Layout = (props) => {
  return (
    <>
      <TopNav />
      <main
        className={props.className}
      >
        {props.children}
      </main>
    </>
  )
}

export default Layout
